<template>
  <label
    :for="id"
    class="flex flex-col text-neutral-200 text-sm"
    :class="{ '!flex-row justify-between items-center': horizontal }"
  >
    <Tooltip
      v-if="tooltipText || tooltipHtml"
      grey-theme
      :label="tooltipLabel"
      :description="tooltipText"
      :html="tooltipHtml"
      :position="tooltipPosition"
      :small-size="tooltipSmall"
      :large-size="tooltipLarge"
      :class="{ 'flex-1': horizontal }"
    >
      <TextSmall class="pb-1">
        {{ title
        }}<span
          v-if="props.required || props.optional"
          :class="{ 'italic text-xs text-neutral-100': optional }"
          >{{ indicator }}</span
        >
        <font-awesome-icon
          icon="fa-circle-info"
          class="text-neutral-200 ml-1"
        />
      </TextSmall>
    </Tooltip>
    <TextSmall v-else class="block pb-1">
      {{ title
      }}<span
        v-if="props.required || props.optional"
        :class="{ 'italic text-xs text-neutral-100': optional }"
        >{{ indicator }}</span
      >
    </TextSmall>
    <slot />
  </label>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { computed } from 'vue'

const $t = i18n.t

const props = defineProps({
  title: String,
  id: String,
  tooltipLabel: String,
  tooltipText: String,
  tooltipHtml: String,
  tooltipPosition: {
    type: String,
    default: 'right'
  },
  tooltipSmall: {
    type: Boolean,
    default: false
  },
  tooltipLarge: {
    type: Boolean,
    default: false
  },
  required: Boolean,
  optional: Boolean,
  horizontal: Boolean
})

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})
</script>
