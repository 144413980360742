<template>
  <TextTiny class="text-neutral-200">{{
    $t('shared.label.required_indication')
  }}</TextTiny>
</template>
<script setup>
import TextTiny from '@/components/shared/font/text/TextTiny.vue'
import i18n from '@/i18n'

const $t = i18n.t
</script>
