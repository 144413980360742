<template>
  <Modal :show-modal="showModal" :loading="loading" @close="$emit('cancel')">
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('customer.project_modal.title')"
      />
    </template>
    <template v-if="account.attributes" #body>
      <template v-if="hasTranslators">
        <div class="flex flex-col gap-4">
          <Select>
            <Label required :title="$t('shared.label.select_workflow')" />
            <SelectSelection
              :value="selected.process"
              @inputChange="onSelectedProcess"
            >
              <SelectOption
                v-for="workflow in validWorkflows"
                :id="workflow.id"
                :key="workflow.id"
                :value="workflow"
              >
                <span>{{ workflow.attributes.display_name }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
          <div>
            <InputField
              :placeholder="$t('shared.placeholder.project_name_optional')"
              :red-border="projectNameAlreadyExists"
              @inputChange="getProjectName"
            />
            <div
              v-if="projectNameAlreadyExists"
              class="text-xs mt-1 text-error-100"
            >
              {{ $t('customer.project.modal.name_exists') }}
              <font-awesome-icon
                icon="fa-solid fa-triangle-exclamation"
                color="red"
              />
            </div>
          </div>
          <div
            v-if="onShowDeadline"
            class="flex flex-col relative items-baseline"
          >
            <div class="flex gap-4 items-center">
              <BtnSecondary
                class="!p-0 text-sm"
                :title="$t('shared.label.click_here')"
                @click="show.deadline = !show.deadline"
              />
              <TextTiny>
                {{ $t('customer.project_modal.preferred_deadline.set') }}
                <i
                  class="fas fa-question-circle relative cursor-pointer text-neutral-200"
                  @mouseenter="show.tooltip = true"
                  @mouseleave="show.tooltip = false"
                />
              </TextTiny>
            </div>
            <TextTiny
              v-if="show.tooltip"
              class="text-neutral-200 leading-relaxed absolute block bg-white z-[9] p-4 rounded top-[40px] left-0 shadow-lg text-center"
            >
              {{ $t('customer.project_modal.preferred_deadline.text') }}
            </TextTiny>
            <div v-if="show.deadline" class="flex flex-col w-full mt-2">
              <TextTiny>
                {{
                  $t('customer.project_modal.preferred_deadline_note')
                }}</TextTiny
              >
              <div class="flex justify-between pt-3">
                <InputDate
                  disable-past-dates
                  :placeholder="deadlineDate"
                  @inputChange="handleDate"
                />
                <Select>
                  <SelectSelection
                    :value="deadlineTime"
                    @inputChange="handleTime"
                  >
                    <SelectOption disabled>
                      <span>{{ $t('shared.label.choose_time') }}</span>
                    </SelectOption>
                    <SelectOption
                      v-for="time in times"
                      :key="time.name"
                      :value="time.name"
                    >
                      <span>{{ time.name }}</span>
                    </SelectOption>
                  </SelectSelection>
                </Select>
              </div>
            </div>
          </div>
          <br v-else />
          <div>
            <div class="flex gap-3 items-center mb-2">
              <RadioButton
                id="copyEditing"
                name="multiling"
                :label="$t('shard.label.one_language')"
                :active="!selectedMultilingual"
                @check="toggleMultilingual(false)"
              />
              <RadioButton
                id="multilingual"
                name="multiling"
                :label="$t('shard.label.multiple_languages')"
                :active="selectedMultilingual"
                @check="toggleMultilingual(true)"
              />
            </div>
            <Select>
              <SelectSelection
                :value="sourceText"
                data-cy="select_soucre"
                @inputChange="onSelectedSource"
              >
                <SelectOption disabled>
                  <span>{{ $t('shared.label.source_lang') }}*</span>
                </SelectOption>
                <SelectOption
                  v-for="source in sourceList"
                  :key="source.code"
                  :value="source.code"
                >
                  <span>{{ $t(`shared.languages.${source.code}`) }}</span>
                </SelectOption>
              </SelectSelection>
            </Select>
          </div>
          <div v-if="selectedMultilingual">
            <!-- Target languages select option -->
            <div class="flex gap-3 items-center mb-2">
              <RadioButton
                id="optional"
                name="targets"
                :label="$t('shared.label.select_from_list')"
                :active="!selectAllTargets"
                @check="toggleAllTargets(false)"
              />
              <RadioButton
                id="all"
                name="targets"
                :label="$t('shared.label.select_all')"
                :active="selectAllTargets"
                @check="toggleAllTargets(true)"
              />
            </div>
            <!-- Target languages -->
            <Select>
              <SelectSelection
                :value="`${$t('shared.label.target_lang')}*`"
                @inputChange="onSelectedTarget"
              >
                <SelectOption disabled>
                  <span>{{ $t('shared.label.target_lang') }}*</span>
                </SelectOption>
                <SelectOption
                  v-for="target in targetList"
                  :key="target.code"
                  :value="target.code"
                  :disabled="selected.targets.includes(target.code)"
                >
                  <span>{{ $t(`shared.languages.${target.code}`) }}</span>
                </SelectOption>
                <template #multiselect v-if="selected.targets.length > 0">
                  <FilterWrap>
                    <FilterItem
                      v-for="(target, targetIndex) in selected.targets"
                      :key="targetIndex"
                      @remove="onRemoveTag(targetIndex)"
                    >
                      <span>{{ $t(`shared.languages.${target}`) }}</span>
                    </FilterItem>
                  </FilterWrap>
                </template>
              </SelectSelection>
            </Select>
          </div>
          <BtnSecondary
            class="block ml-auto !p-0"
            :title="$t('shared.button.create_folder')"
            @click="show.newFolder = !show.newFolder"
          />
          <div
            v-if="show.newFolder"
            class="flex justify-between items-center gap-6 bg-neutral-50 rouded p-3 py-6"
          >
            <div class="relative w-full">
              <InputField
                :placeholder="$t('shared.placeholder.folder_name')"
                :red-border="folderNameAlreadyExists"
                :value="folderName"
                @inputChange="({ value }) => (folderName = value)"
              />
              <div
                v-if="folderNameAlreadyExists"
                class="absolute -bottom-[19px] text-xs mt-1 text-error-100"
              >
                {{ $t('customer.folder.modal.name_exists') }}
                <font-awesome-icon
                  icon="fa-solid fa-triangle-exclamation"
                  class="text-error-100"
                />
              </div>
            </div>
            <BtnsWrapper class="flex gap-2 !mt-0">
              <BtnPrimary
                :title="$t('shared.button.save')"
                :disabled="folderNameAlreadyExists"
                @click="onCreateNewFolder"
              />
              <BtnSecondary
                class="!px-2"
                :title="$t('shared.button.cancel')"
                @click="
                  () => {
                    show.newFolder = false
                    folderName = ''
                  }
                "
              />
            </BtnsWrapper>
          </div>
          <Select>
            <SelectSelection
              :value="folderText"
              @inputChange="onSelectedFolder"
            >
              <SelectOption disabled>
                <span>{{ $t('shared.placeholder.project_folder') }}</span>
              </SelectOption>
              <SelectOption
                v-for="(folder, i) in allFolders"
                :key="folder.attributes.created_at + i"
                :value="folder.attributes.name"
              >
                <span>{{ folder.attributes.name }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
          <div>
            <Label
              optional
              :title="$t('shared.label.specific_instructions.customer')"
            />
            <Textarea
              class="min-h-[50px]"
              :value="instructionText"
              vertical
              :placeholder="$t('shared.label.specific_instructions_text')"
              @inputChange="getInstructions"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <InfoBlock>
          <TextRegular>
            <i18n-t keypath="shared.label.not_found_freelancers" tag="p">
              <template #here>
                <router-link
                  :to="{ name: 'clientTeamStepOne' }"
                  @click="$emit('cancel')"
                >
                  <span class="text-primary-100 hover:underline">{{
                    $t('shared.button.here')
                  }}</span>
                </router-link>
              </template>
            </i18n-t>
          </TextRegular>
        </InfoBlock>
      </template>
    </template>
    <template v-if="hasTranslators" #footer>
      <RequiredInfoLabel />
      <BtnsWrapperVertical>
        <BtnPrimary
          class="w-full"
          data-cy="create_project"
          :title="$t('shared.button.create_project')"
          :disabled="disableButton"
          @click="onCreateProject"
        />
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          @click="$emit('cancel')"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import InfoBlock from '@/components/shared/InfoBlock'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import RadioButton from '@/components/shared/btn/RadioButton'
import FilterItem from '@/components/shared/filter/new/FilterItem'
import FilterWrap from '@/components/shared/filter/new/FilterWrap'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputDate from '@/components/shared/input/InputDate'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import RequiredInfoLabel from '@/components/shared/label/RequiredInfoLabel'
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import Textarea from '@/components/shared/textarea/Textarea'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const emit = defineEmits(['cancel'])
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  document: {
    type: String,
    default: ''
  }
})

const loading = ref(false)
const folderName = ref('')
const selected = ref({
  process: {},
  source: null,
  target: null,
  projectName: '',
  targets: [],
  folder: {
    name: '',
    id: null
  },
  deadline: {
    date: null,
    time: null,
    dateTime: null
  }
})
const show = ref({
  deadline: false,
  newFolder: false,
  tooltip: false
})
const selectAllTargets = ref(false)
const selectedMultilingual = ref(false)
const instructionText = ref(null)

const allProjects = computed(() => store.state.workspace.allProjects)
const accountLangPair = computed(() => store.state.account.accountLangPair)
const account = computed(() => store.state.account.account)
const allFolders = computed(() => store.state.folder.allFolders)
const translators = computed(() => store.state.translators.translators)

const folderNameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    allFolders.value.some(
      (obj) =>
        obj.attributes.name.toUpperCase() === folderName.value.toUpperCase()
    )
  )
})
const projectNameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    allProjects.value.some(
      (obj) =>
        obj.attributes.name.toUpperCase() ===
        selected.value.projectName.toUpperCase()
    )
  )
})
const onShowDeadline = computed(() => {
  return selected.value.process != 'Translate Yourself'
})
const sourceText = computed(() => {
  if (selected.value.source) return selected.value.source
  return `${$t('shared.label.source_lang')}*`
})
const folderText = computed(() => {
  if (selected.value.folder.name) return selected.value.folder.name
  return $t('shared.placeholder.project_folder')
})
const deadlineDate = computed(() => {
  if (selected.value.deadline.date) return selected.value.deadline.date
  return $t('shared.label.choose_date')
})
const deadlineTime = computed(() => {
  if (selected.value.deadline.time) return selected.value.deadline.time
  return $t('shared.label.choose_time')
})
const times = computed(() => {
  let times = []
  for (let i = 0; i < 24; i++) {
    times.push({
      value: i,
      name: `${i < 10 ? '0' : ''}${i}:00`
    })
  }
  return times
})
const sourceList = computed(() => {
  return accountLangPair.value['copyediting'].en.target_languages
})
const targetList = computed(() => {
  if (!selected.value.source) {
    return []
  }
  return sourceList.value
})
const disableButton = computed(() => {
  if (
    selected.value.targets.length > 0 &&
    selected.value.source &&
    !projectNameAlreadyExists.value &&
    !loading.value
  ) {
    return false
  } else {
    return true
  }
})
const hasTranslators = computed(() => {
  return Object.values(translators.value)
    .map((t) => t.attributes)
    .some((t) => t.services.some((s) => s.attributes.type === 'copyediting'))
})
const validWorkflows = computed(() => {
  return [
    {
      ...account.value.attributes.workflows.find(
        (w) => w.attributes.identifier == 'copyediting'
      )
    }
  ]
})

const createNewFolder = (payload) =>
  store.dispatch('workspace/createNewFolder', payload)
const createDocumentProject = (payload) =>
  store.dispatch('projects/createDocumentProject', payload)

function toggleMultilingual(isMultilingual) {
  selectedMultilingual.value = isMultilingual
  selected.value.source = null
  selected.value.target = null
  selected.value.targets = []
  selectAllTargets.value = false
}
function toggleAllTargets(all) {
  if (all && targetList.value) {
    selected.value.targets = targetList.value.map((target) => target.code)
  } else {
    selected.value.targets = []
  }
  selectAllTargets.value = all
}
function handleDate(date) {
  let format = moment(date).format('YYYY-MM-DD')
  selected.value.deadline.date = format
}
function handleTime(value) {
  selected.value.deadline.time = value
}
function onSelectedProcess(process) {
  selected.value.process = process
  selected.value.source = null
  selected.value.target = null
  selected.value.targets = []
  selectAllTargets.value = false
}
function onSelectedSource(sourceType) {
  selected.value.source = sourceType
  selected.value.target = null
  selected.value.targets = []
  selectAllTargets.value = false
  selected.value.targets.push(selected.value.source)
}
function onSelectedTarget(targetType) {
  if (selected.value.targets.includes(targetType)) return
  selected.value.targets.push(targetType)
  selected.value.target = targetType
}
function onSelectedFolder(folder) {
  let folders = allFolders.value
  let selectedFolder
  folders.forEach((obj) => {
    if (obj.attributes.name == folder) {
      selectedFolder = {
        name: obj.attributes.name,
        id: obj.id
      }
      return
    }
  })
  selected.value.folder = selectedFolder
}
function onRemoveTag(i) {
  selected.value.targets.splice(i, 1)
}
function onCreateNewFolder() {
  createNewFolder({ folderName: folderName.value }).finally(() => {
    show.value.newFolder = false
    folderName.value = null
  })
}
function getProjectName(project) {
  selected.value.projectName = project.value
}
function getInstructions(value) {
  instructionText.value = value
}
function onCreateProject() {
  loading.value = true
  let data = {
    source: selected.value.source,
    targets: [],
    workflow_id: selected.value.process.id,
    content: props.document,
    instructions: instructionText.value,
    copyedit_source_language: true,
    folderId: selected.value.folder.id,
    projectName: selected.value.projectName,
    deadline: selected.value.deadline.dateTime
      ? moment(selected.value.deadline.dateTime)
          .subtract(moment(selected.value.deadline.dateTime).utcOffset(), 'm')
          .format('YYYY-MM-DD HH:mm:ss')
      : null
  }
  let allSelectedTargets = selected.value.targets
  allSelectedTargets.forEach((lang) => {
    return data.targets.push(lang)
  })
  if (!data.targets.includes(data.source)) {
    data.copyedit_source_language = false
  }
  createDocumentProject(data).then(() => {
    emit('cancel')
    loading.value = false
  })
}
watch(
  () => selected.value.targets,
  (targets) => {
    if (targets.length === 0) {
      selected.value.target = null
    }
  }
)
watch(
  () => selected.value.deadline,
  (targets) => {
    if (targets.date && targets.time) {
      for (const time of times.value) {
        if (time.name == targets.time) {
          const timeAndDateFormat = moment(targets.date)
            .set('hour', time.value)
            .format('YYYY-MM-DD HH:mm:ss')
          selected.value.deadline.dateTime = timeAndDateFormat
        }
      }
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.showModal,
  () => {
    loading.value = false
    folderName.value = ''
    selected.value = {
      process: validWorkflows.value[0],
      source: null,
      target: null,
      projectName: '',
      targets: [],
      files: [],
      folder: {
        name: null,
        id: null
      },
      deadline: {
        date: null,
        time: null,
        dateTime: null
      }
    }
    show.value = {
      deadline: false,
      newFolder: false,
      tooltip: false
    }
    selectAllTargets.value = false
    instructionText.value = null
    selectedMultilingual.value = false
  }
)
</script>
