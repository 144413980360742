<template>
  <textarea
    :id="id"
    :name="id"
    label=""
    class="!rounded-lg outline-none resize-y border p-2.5 w-full text-black placeholder:text-neutral-100 text-sm bg-neutral-50 border-neutral-80 focus:ring-0 focus:border-primary-100 focus-visible:outline-none"
    :class="{
      'min-h-[200px]': size === 'md',
      'resize-y': vertical,
      'cursor-not-allowed': disabled
    }"
    :value="value"
    :placeholder="placeholder ? `${placeholder}${indicator}` : ''"
    :maxlength="max"
    :disabled="disabled"
    @input="$emit('inputChange', $event.target.value)"
  />
</template>
<script setup>
import i18n from '@/i18n'
import { computed } from 'vue'

const $t = i18n.t

const emit = defineEmits(['inputChange'])
const props = defineProps({
  id: String,
  value: String,
  placeholder: String,
  max: Number,
  vertical: Boolean,
  disabled: Boolean,
  required: Boolean,
  optional: Boolean,
  size: {
    type: String,
    default: 'sm',
    validator(value, props) {
      return ['sm', 'md'].includes(value)
    }
  }
})

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})
</script>
