<template>
  <Modal :show-modal="showModal" @close="$emit('close')">
    <template #header>
      <div class="text-center">
        <TitleRegular :title="title" />
        <TextSmall class="text-neutral-200">
          {{ subtitle }}
        </TextSmall>
      </div>
    </template>
    <template #body>
      <InputField
        id="rename_input"
        :required="true"
        :value="getDocName"
        :red-border="showWarning"
        @inputChange="handleDocName"
      />
      <div v-if="showWarning" class="text-xs mt-1 text-error-100">
        {{
          templatesModal
            ? $t('customer.document.modal.name_exists')
            : $t('customer.template.modal.name_exists')
        }}
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          color="red"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          class="w-full"
          data-cy="save_rename"
          :title="
            documentsModal
              ? $t('shared.content_generation.modals.save_document')
              : $t('shared.content_generation.modals.save_template')
          "
          :disabled="disableButton"
          @click="handleSave"
        />
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          type="button"
          data-cy="close_rename"
          @click="$emit('close')"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import AllowedHtmlContent from '@/utils/allowedHtmlContent'
import HtmlSanitizer from 'jitbit-html-sanitizer'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const sanitizer = new HtmlSanitizer(AllowedHtmlContent)
const titleSanitizer = new HtmlSanitizer({
  allowedTags: ['body'],
  allowedAttributes: [],
  allowedCss: [],
  allowedSchemas: []
})

const $t = i18n.t
const store = useStore()
const emit = defineEmits(['close', 'saveTemplate', 'createDocument'])
const props = defineProps({
  documentsModal: {
    type: Boolean,
    default: false
  },
  templatesModal: {
    type: Boolean,
    default: false
  },
  showModal: {
    type: Boolean,
    default: false
  },
  newTemplate: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: null
  },
  newDocument: {
    type: Boolean,
    default: false
  },
  isHeader: {
    type: Boolean,
    default: false
  },
  content: {
    type: Object,
    default: () => {
      return { content: null, raw_content: null }
    }
  },
  autoTitle: {
    type: String,
    default: ''
  }
})

const documentNameAlreadyExists = computed(() => {
  const foundName = documents.value.find(
    (obj) =>
      obj.attributes.title.toUpperCase() === docName.value.toUpperCase() &&
      obj.attributes.title.toUpperCase() != getDocName.value.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const templateNameAlreadyExists = computed(() => {
  const foundName = templates.value.find(
    (obj) =>
      obj.attributes.title.toUpperCase() === docName.value.toUpperCase() &&
      obj.attributes.title.toUpperCase() != getDocName.value.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const showWarning = computed(
  () =>
    (props.documentsModal && documentNameAlreadyExists.value) ||
    (props.templatesModal && templateNameAlreadyExists.value)
)

const documents = computed(() => store.state.contentGeneration.documents)
const templates = computed(() => store.state.contentGeneration.templates)

const currentTemplate = computed(
  () => store.state.contentGeneration.currentTemplate
)
const currentDocument = computed(
  () => store.state.contentGeneration.currentDocument
)
const updateTemplate = (payload) =>
  store.dispatch('contentGeneration/updateTemplate', payload)
const updateDocument = (payload) =>
  store.dispatch('contentGeneration/updateDocument', payload)

const title = computed(() => {
  if (props.templatesModal && !props.newTemplate)
    return $t('shared.content_generation.modals.rename_template')
  if (props.templatesModal && props.newTemplate)
    return $t('shared.content_generation.modals.save_template')
  if (props.documentsModal && props.newDocument)
    return $t('shared.content_generation.modals.save_document')
  return $t('shared.content_generation.modals.rename_document')
})
const subtitle = computed(() => {
  if (props.templatesModal && !props.newTemplate)
    return $t('shared.content_generation.modals.change_template_name')
  if (props.templatesModal && props.newTemplate)
    return $t('shared.content_generation.modals.save_tempate_subtitle')
  if (props.documentsModal && props.newDocument)
    return $t('customer.sub_nav.caas.save_your_docs')
  return $t('shared.content_generation.modals.change_document_name')
})

const getDocName = computed(() => {
  if (props.item) return props.item.attributes.title
  if (props.templatesModal)
    return (
      currentTemplate?.value?.attributes.title ||
      $t('shared.content_generation.new_template')
    )
  if (props.documentsModal)
    return (
      currentDocument?.value?.attributes.title ||
      props.autoTitle ||
      $t('shared.content_generation.new_document')
    )
  return docName.value
})

const docName = ref(
  props.documentsModal
    ? props.autoTitle || $t('shared.content_generation.new_document')
    : $t('shared.content_generation.new_template')
)

const disableButton = computed(() => {
  if (!docName.value || showWarning.value) return true
  return false
})
function handleDocName(val) {
  docName.value = val.value
}
function handleSave() {
  if (props.documentsModal) {
    if (props.newDocument) {
      emit('createDocument', docName.value)
    } else {
      updateDocument({
        isHeader: props.isHeader,
        id: props.item ? props.item.id : currentDocument?.value?.id,
        attributes: {
          title: titleSanitizer.sanitizeHtml(docName.value),
          template_id: props.item
            ? props.item.attributes.template_id
            : currentDocument.value.attributes.template_id,
          content: props.item
            ? sanitizer.sanitizeHtml(props.item.attributes.content || '')
            : sanitizer.sanitizeHtml(props.content.content || ''),
          raw_content: props.item
            ? sanitizer.sanitizeHtml(props.item.attributes.raw_content || '')
            : sanitizer.sanitizeHtml(props.content.raw_content || '')
        }
      })
    }
  } else {
    if (props.newTemplate) {
      emit('saveTemplate', docName.value)
    } else {
      const { created_at, updated_at, title, ...attributes } =
        props.item.attributes
      const payload = {
        id: props.item.id,
        attributes: {
          attributes,
          title: titleSanitizer.sanitizeHtml(docName.value)
        }
      }
      updateTemplate(payload)
    }
  }
  emit('close')
}
</script>
