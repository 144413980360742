<template>
  <Modal :show-modal="showModal" @close="$emit('close')">
    <template #header>
      <TitleRegular
        :title="
          documentsModal
            ? $t('shared.content_generation.delete_document')
            : $t('shared.content_generation.delete_template')
        "
      />
      <TextSmall v-if="documentsModal">
        {{ $t('shared.content_generation.delete_document.text') }}
      </TextSmall>
      <TextSmall v-if="templatesModal">
        {{ $t('shared.content_generation.delete_template.text') }}
      </TextSmall>
    </template>
    <template #body>
      <TextSmall class="mt-8 bg-neutral-50 rounded-sm p-4">{{
        title
      }}</TextSmall>
    </template>
    <template #footer>
      <BtnsWrapper>
        <BtnPrimary
          color="error"
          data-cy="delete"
          :title="
            documentsModal
              ? $t('shared.content_generation.delete_document')
              : $t('shared.content_generation.delete_template')
          "
          @click="handleDelete"
        />

        <BtnSecondary
          :title="$t('shared.button.cancel')"
          type="button"
          data-cy="close_delete"
          @click="$emit('close')"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Modal from '@/components/shared/Modal'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const emit = defineEmits(['close'])

const props = defineProps({
  documentsModal: {
    type: Boolean,
    default: false
  },
  templatesModal: {
    type: Boolean,
    default: false
  },
  showModal: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: null
  }
})

const title = computed(() => {
  return props.item.attributes.title
})

const documentsPages = computed(
  () => store.state.contentGeneration.documentsPages
)
const templatesPages = computed(
  () => store.state.contentGeneration.templatesPages
)

const deleteTemplate = (payload) =>
  store.dispatch('contentGeneration/deleteTemplate', payload)
const getTemplates = (payload) =>
  store.dispatch('contentGeneration/getTemplates', payload)
const deleteDocument = (payload) =>
  store.dispatch('contentGeneration/deleteDocument', payload)
const getDocuments = (payload) =>
  store.dispatch('contentGeneration/getDocuments', payload)

async function handleDelete() {
  if (props.templatesModal) {
    await deleteTemplate(props.item.id)
    await getTemplates({
      currentPage: templatesPages.value.current
    })
  } else {
    await deleteDocument(props.item.id)
    await getDocuments({
      currentPage: documentsPages.value.current
    })
  }
  emit('close')
}
</script>
