<template>
  <Modal
    close
    :show-modal="showCreateTermModal"
    :loading="loading"
    @close="handleClose"
  >
    <template #header>
      <TitleRegular
        class="text-center pb-2 border-b border-neutral-80"
        :title="$t('customer.lang_assets.create_modal.term')"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <Label required :title="$t('shared.label.language')">
          <Select>
            <SelectSelection
              rounded
              data-cy="language_select"
              :value="selected.language || $t('shared.label.choose_language')"
              @inputChange="onLanguage"
            >
              <SelectOption disabled>
                <span>{{ $t('shared.label.choose_language') }}</span>
              </SelectOption>
              <SelectOption
                v-for="(lang, i) in availableLanguages"
                :key="i"
                :value="lang"
              >
                <span>{{ $t(`shared.languages.${lang}`) }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
        </Label>
        <Label
          required
          :title="$t('customer.lang_assets.create_modal.term_value')"
        >
          <textarea
            data-cy="term_value"
            v-model="selected.value"
            required
            class="rounded border border-neutral-80 resize-none outline-none hover:border-primary-100 text-sm focus:ring-0"
          />
        </Label>
      </div>
    </template>
    <template #footer>
      <RequiredInfoLabel />
      <BtnsWrapperVertical>
        <BtnPrimary
          class="w-full"
          data-cy="save_term"
          :title="$t('shared.button.save')"
          :disabled="disableButton || loading"
          @click="onSave"
        />
        <BtnSecondary
          data-cy="cancel_term"
          :title="$t('shared.button.cancel')"
          @click="handleClose"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Label from '@/components/shared/label/Label'
import RequiredInfoLabel from '@/components/shared/label/RequiredInfoLabel'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const selected = ref({
  value: '',
  language: ''
})

const currentTb = computed(() => store.state.termBanks.currentTb)
const editedTerm = computed(() => store.state.termBanks.editedTerm)
const showCreateTermModal = computed(
  () => store.state.modals.showCreateTermModal
)

const editMode = computed(() => editedTerm.value)
const disableButton = computed(
  () => !selected.value.value || !selected.value.language
)
const availableLanguages = computed(() =>
  !editMode.value
    ? currentTb.value.attributes.languages
    : editedTerm.value.missingLangs
)

const toggleCreateTermModal = () => store.commit('modals/toggleCreateTermModal')

const setEditedTerm = () => store.commit('termBanks/setEditedTerm')
const createTerm = (payload) => store.dispatch('termBanks/createTerm', payload)

function onLanguage(lang) {
  selected.value.language = lang
}
async function onSave() {
  loading.value = true
  try {
    const data = {
      ...selected.value,
      ...(editMode.value && editedTerm.value
        ? { concept_id: editedTerm.value.term.id }
        : {})
    }
    await createTerm(data)
    handleClose()
  } finally {
    loading.value = false
  }
}

function resetSelected() {
  selected.value = {
    value: '',
    language: ''
  }
}

function handleClose() {
  resetSelected()
  setEditedTerm(null)
  toggleCreateTermModal()
}
</script>
