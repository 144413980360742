<template>
  <Modal
    :show-modal="showRenameModal"
    :loading="loading"
    @close="toggleRenameModal(false)"
  >
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('shared.button.rename_folder')"
      />
    </template>
    <template #body>
      <div class="flex flex-col">
        <InputField
          :placeholder="$t('shared.placeholder.new_name')"
          :value="folder.name"
          @inputChange="handleFolderName"
        />
      </div>
      <div v-if="nameAlreadyExists" class="text-xs mt-1 text-error-100">
        {{ $t('customer.folder.modal.name_exists') }}
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          class="text-error-100"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          :title="$t('shared.button.update')"
          class="w-full"
          :disabled="disableButton"
          @click="handleUpdateFolderName"
        />
        <BtnSecondary
          :title="$t('shared.button.close')"
          @click="toggleRenameModal(false)"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const folder = ref({
  id: '',
  name: ''
})

const allFolders = computed(() => store.state.folder.allFolders)
const renameFolder = computed(() => store.state.workspace.renameFolder)
const showRenameModal = computed(() => store.state.modals.showRenameModal)

const disableButton = computed(() => {
  return nameAlreadyExists.value || folder.value.name == null || loading.value
})
const nameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    allFolders.value.some(
      (obj) =>
        obj.attributes.name.toUpperCase() === folder.value.name.toUpperCase() &&
        obj.attributes.name.toUpperCase() !==
          renameFolder.value.name.toUpperCase()
    )
  )
})

const toggleRenameModal = () => store.commit('modals/toggleRenameModal')
const updateFolderName = (payload) =>
  store.dispatch('workspace/updateFolderName', payload)

watch(
  () => showRenameModal.value,
  (isOpen) => {
    if (isOpen) {
      folder.value.id = renameFolder.value.id
      folder.value.name = renameFolder.value.name
    }
  }
)

function handleFolderName({ value }) {
  folder.value.name = value
}

async function handleUpdateFolderName() {
  loading.value = true
  try {
    await updateFolderName(folder.value)
  } finally {
    loading.value = false
  }
}
</script>
