<template>
  <div
    data-cy="toast"
    class="snackbar invisible min-w-[250px] bg-white shadow-md p-4 pr-2 fixed z-[100000] left-1/2 -translate-x-1/2 top-[15px] border-[1.5px] rounded-lg max-w-[800px] flex gap-4 items-center mobileLandscape:min-w-[400px]"
    :class="{
      '!visible animate-snackbarsequence': show,
      'border-success-200 text-success-200': success,
      'border-error-200 text-error-200': error,
      'border-warning-200 text-warning-200': warning,
      'border-info-200 text-info-200': info
    }"
  >
    <div
      class="w-full max-w-[38px] h-[38px] flex items-center justify-center rounded-lg"
      :class="{
        show,
        'bg-success-80': success,
        'bg-error-80': error,
        'bg-warning-80': warning,
        'bg-info-80': info
      }"
    >
      <font-awesome-icon v-if="type" :icon="icons[type]" class="text-2xl" />
    </div>
    <div class="flex-1 h-max">
      <template v-if="hasDynamicValues">
        <i18n-t
          class="!mb-0.5"
          :class="{
            show,
            'text-success-200': success,
            'text-error-200': error,
            'text-warning-200': warning,
            'text-info-200': info
          }"
          :keypath="title"
        ></i18n-t>
        <TextSmall class="text-neutral-200">
          <i18n-t
            tag="pre"
            :keypath="message"
            class="!font-poppins break-words whitespace-pre-wrap"
          >
            <template #workspaceLink>
              <router-link
                :to="{ name: 'clientOverview' }"
                class="text-primary-100 underline"
              >
                {{ $t('shared.labels.workspace') }}
              </router-link>
            </template>
            <template
              v-for="option in options"
              :key="option.name"
              #[option.name]
            >
              <span :class="{ 'font-bold': boldOptions }">{{
                option.value
              }}</span>
            </template>
          </i18n-t>
        </TextSmall>
      </template>
      <template v-else>
        <TitleSmall
          class="!mb-0.5"
          :title="title"
          :class="{
            show,
            'text-success-200': success,
            'text-error-200': error,
            'text-warning-200': warning,
            'text-info-200': info
          }"
        />
        <TextSmall class="text-neutral-200">
          <pre class="!font-poppins break-words whitespace-pre-wrap">{{
            message
          }}</pre>
        </TextSmall>
      </template>
    </div>
    <button
      class="font-light text-neutral-100 text-lg cursor-pointer rounded-lg hover:bg-neutral-50 inline-flex items-center justify-center w-[38px] h-[38px] hover:text-neutral-200"
      data-cy="close_toast"
      @click="close"
    >
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const time = ref(null)
const icons = ref({
  info: 'fa-circle-info',
  warning: 'fa-circle-exclamation',
  error: 'circle-xmark',
  success: 'circle-check'
})

const queue = computed(() => store.state.toast.queue)
const showToast = computed(() => store.state.toast.showToast)

const type = computed(() => {
  return queue.value[0] && queue.value[0].type
})
const title = computed(() => {
  return queue.value[0] && queue.value[0].title
})
const hasDynamicValues = computed(() => {
  return queue.value[0] && queue.value[0].hasDynamicValues
})
const boldOptions = computed(() => {
  return queue.value[0] && queue.value[0].boldOptions
})
const message = computed(() => {
  return queue.value[0] && queue.value[0].message
})
const options = computed(() => {
  return queue.value[0] && queue.value[0].options
})
const show = computed(() => {
  return showToast.value
})
const success = computed(() => {
  return type.value === 'success'
})
const error = computed(() => {
  return type.value === 'error'
})
const warning = computed(() => {
  return type.value === 'warning'
})
const info = computed(() => {
  return type.value === 'info'
})

const currentTimeout = computed(() => {
  if (error.value) {
    return 10000
  } else {
    return 5000
  }
})

const closeToast = () => store.commit('toast/closeToast')
const openToast = () => store.commit('toast/openToast')

watch(
  () => queue.value.length,
  (length) => {
    if (length > 0) {
      setTimeout(() => {
        openToast()
      }, 500)
    }
  }
)

watch(
  () => show.value,
  (newValue) => {
    if (newValue) {
      time.value = setTimeout(() => {
        closeToast()
      }, currentTimeout.value)
    } else {
      clearTimeout(time.value)
    }
  }
)

function close() {
  clearTimeout(time.value)
  closeToast()
}
</script>
