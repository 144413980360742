<template>
  <label
    :for="id"
    class="text-neutral-200 text-sm flex gap-1"
    @click="emit('check')"
  >
    <input
      :id="id"
      type="radio"
      :disabled="disabled"
      :checked="active"
      :name="name"
      class="peer hidden focus:ring-transparent"
    />
    <span
      class="mt-0.5 flex items-center justify-center w-4 h-4 rounded-full bg-neutral-50 border border-neutral-80 text-primary-100 peer-checked:bg-primary-100"
      ><span class="w-1.5 h-1.5 rounded-full bg-neutral-50"></span>
    </span>
    <div>
      <TextSmall :bold="description.length > 0" neutral>{{ label }}</TextSmall>
      <TextTiny lightNeutral>{{ description }}</TextTiny>
      <slot />
    </div>
  </label>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
const emit = defineEmits(['check'])
const props = defineProps({
  id: String,
  label: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  active: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: 'method'
  },
  disabled: Boolean
})
</script>
