<template>
  <div class="flex flex-col !m-0 [&:not(:last-child)]:!mb-0">
    <div
      :class="{
        '[&>.vue-tel-input]:outline-none [&>.vue-tel-input]:!border-error-100':
          showWarning
      }"
    >
      <slot name="label" />
      <vue-tel-input
        :key="keyName"
        v-model="inputValue"
        :class="[
          '!text-black placeholder:!text-neutral-200 !p-2.5 !w-auto !border !border-neutral-80 rounded-lg !text-base hover:!border-primary-100 focus-within:!border-primary-100 [&.vue-tel-input]:!p-[2px] [&.vue-tel-input]:focus-within:!shadow-none',
          {
            '!border-error-100': isRequired,
            'cursor-not-allowed !bg-neutral-50 [&_input]:!bg-neutral-50':
              disabled
          }
        ]"
        :input-options="inputOptions"
        :data-cy="id"
        :disabled="disabled"
        mode="national"
        valid-characters-only
        auto-format
        :auto-default-country="false"
        :default-country="country"
        :preferred-countries="preferredCountries"
        @validate="validate"
        @on-input="onUpdatePhone"
        @country-changed="handleCountry"
      ></vue-tel-input>
    </div>
    <div
      v-if="showWarning"
      data-cy="phone_validation"
      class="flex justify-between"
    >
      <p class="w-full text-error-100 relative text-sm">
        {{ $t('shared.validation.phone_number') }}
      </p>
    </div>
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, onMounted, ref } from 'vue'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

const $t = i18n.t

const emit = defineEmits(['inputChange', 'validate'])
const props = defineProps({
  id: String,
  type: {
    type: String,
    default: 'phone'
  },
  value: String,
  disabled: Boolean,
  placeholder: String,
  required: {
    type: Boolean,
    default: true
  }
})

const inputOptions = ref({
  placeholder: props.placeholder,
  maxlength: 20
})
const inputValue = ref(props.value)
const isValid = ref(true)
const country = ref(null)
const preferredCountries = ref([
  'dk',
  'se',
  'de',
  'fr',
  'be',
  'gb',
  'es',
  'it',
  'pt'
])
const keyName = ref(0)

const showWarning = computed(() => {
  if (!props.value) return false
  return !isValid.value && inputValue.value.toString().length > 0
})

const isRequired = computed(() => {
  if (!props.required) {
    return false
  } else {
    return inputValue.value === ''
  }
})

onMounted(() => {
  setTimeout(() => {
    if (inputValue.value) {
      inputValue.value = inputValue.value.trim()
    }
    keyName.value += 1
  }, 1000)
})

function onUpdatePhone(_, payload) {
  if (payload) {
    country.value = payload.countryCode
    inputValue.value = payload.formatted
    emit('inputChange', { value: payload.number })
  }
}

const validate = _.debounce(function (payload) {
  const valid = payload.valid === undefined ? true : payload.valid
  isValid.value = valid
  emit('validate', valid)
}, 300)

function handleCountry(country) {
  country.value = country.iso2
}
</script>
