<template>
  <div class="grid grid-cols-[max-content_1fr]">
    <CheckboxBtn
      class="!mr-0 !mt-0.5"
      data-cy="agreement_option"
      @click="onClick"
      :is-active="toggle"
    />
    <slot />
  </div>
</template>
<script setup>
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import { ref, watch } from 'vue'
const emit = defineEmits(['onClick'])
const props = defineProps({
  value: Boolean
})
const toggle = ref(false)
watch(
  () => props.value,
  (newVal) => {
    toggle.value = newVal
  },
  { deep: true, immediate: true }
)

function onClick() {
  toggle.value = !toggle.value
  emit('onClick', toggle.value)
}
</script>
